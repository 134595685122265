import { Image } from "react-grid-gallery";

export const images: Image[] = [
  {
    src: "/galeria/1.jpg", 
    width: 320,
    height: 174,
    caption: "After Rain (Jeshu John - designerspics.com)",
  },
  {
    src: "/galeria/2.png",
    width: 320,
    height: 212,
    caption: "Boats (Jeshu John - designerspics.com)",
  },
  {
    src: "/galeria/3.png",
    width: 320,
    height: 212,
    caption: "Color Pencils (Jeshu John - designerspics.com)",
  },
  {
    src: "/galeria/4.png",
    width: 320,
    height: 213,
    caption: "Red Apples with other Red Fruit (foodiesfeed.com)",
  },
  {
    src: "/galeria/5.png",
    width: 320,
    height: 183,
    caption: "37H (gratispgraphy.com)",
  },
  {
    src: "/galeria/6.png",
    width: 240,
    height: 320,
  },
  {
    src: "/galeria/7.png",
    width: 320,
    height: 190,
    caption: "286H (gratisography.com)",
  },
  {
    src: "/galeria/8.png",
    width: 320,
    height: 174,
    caption: "After Rain (Jeshu John - designerspics.com)",
  },
  {
    src: "/galeria/9.jpg",
    width: 320,
    height: 212,
    caption: "Boats (Jeshu John - designerspics.com)",
  },
  {
    src: "/galeria/10.jpg",
    width: 320,
    height: 212,
    caption: "Color Pencils (Jeshu John - designerspics.com)",
  },
  {
    src: "/galeria/15.jpg",
    width: 200,
    height: 213,
    caption: "Red Apples with other Red Fruit (foodiesfeed.com)",
  },
  {
    src: "/galeria/12.jpg",
    width: 320,
    height: 183,
    caption: "37H (gratispgraphy.com)",
  },
  {
    src: "/galeria/13.jpg",
    width: 240,
    height: 320,
    caption: "8H (gratisography.com)",
  },
  {
    src: "/galeria/14.jpg",
    width: 320,
    height: 190,
    caption: "286H (gratisography.com)",
  },
  {
    src: "/galeria/11.jpg",
    width: 320,
    height: 190,
    caption: "286H (gratisography.com)",
  }
];
