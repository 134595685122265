import React, { useState } from 'react';
import emailjs from 'emailjs-com';

// Definimos el estado inicial si fuera necesario para más complejidad
interface FormState {
    nombre: string;
    email: string;
    consulta: string;
    phone: string;
}

const FormContacto = () => {
    const [formState, setFormState] = useState<FormState>({ nombre: '', email: '', consulta: '', phone: '' });
    const [isSubmitted, setIsSubmitted] = useState(false);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        // Aquí puedes manejar el envío del formulario, como enviar a un servidor o validar datos
        emailjs.sendForm('service_fpppdjt', 'template_zbutpbi', event.currentTarget, '5xB06EW2-fF8njb42')
            .then((result) => {
                window.location.href = "#check";
                setIsSubmitted(true);
            }, (error) => {
                console.log('Error al enviar email', error.text);
                alert('Error al enviar tu mensaje. Por favor intenta de nuevo.');
            });
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
        const { name, value } = event.target;
        setFormState(prevState => ({ ...prevState, [name]: value }));
    };

    return (
        <>
            <div className="media-container mt-5">
                <div className="media-item">
                    <img src="banner/Izquierda.png" alt="Imagen 1" />
                </div>
                <div className="media-item">
                    <video controls>
                        <source src="banner/Centro.mp4" type="video/mp4" />
                        Tu navegador no soporta videos HTML5.
                    </video>
                </div>
                <div className="media-item">
                    <img src="banner/Derecho.png" alt="Imagen 2" />
                </div>
            </div>
            <h3 className='mx-auto text-center pb-5 pt-5 fw-bolder fs-4' >
                Cotiza con nosotros
            </h3>
            <p className='text-center'>
                Cotiza con nosotros, agenda una visita a terreno o envíanos tus planos. Tenemos la mejor disposición para ayudarte en todo lo que necesitas, adaptándonos a tus necesidades y guiándote a la mejor opción.
            </p>
            <div className='container mt-5'>
                <h3 className='fs-5 mb-3'>Contacto</h3>
                <div className='footer-data'>
                    <p><i className='bi bi-envelope'></i><a href="mailto:serv.arq.vpm@gmail.com">serv.arq.vpm@gmail.com</a></p>
                    <p>
                        <i className="bi bi-telephone"></i>
                        <a href="tel:+56927229099">+56927229099</a>
                        <a href="tel:+56994519649">+56994519649</a>
                    </p>

                    <p><i className="bi bi-instagram"></i><a href="https://www.instagram.com/serv.arq.vpm" target="_blank" rel="noopener noreferrer">serv.arq.vpm</a></p>
                    <p><i className='bi bi-whatsapp'></i><a href='https://wa.me/56927229099' target="_blank" rel="noopener noreferrer">+56927229099</a></p>

                </div>
            </div>
            {isSubmitted &&
                <div className="alert alert-success mt-5" id="check" role="alert">
                    <h4 className="alert-heading">¡Bien hecho!</h4>
                    <p>A la brevedar atenderemos tus consultas y seras contactado!</p>
                </div>
            }
            <form onSubmit={handleSubmit} className="container mt-5">
                <div className="mb-3 col-sm-6" >
                    <label htmlFor="nombre" className="form-label">Nombre</label>
                    <input
                        type="text"
                        className="form-control"
                        id="nombre"
                        name="nombre"
                        value={formState.nombre}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="mb-3 col-sm-6">
                    <label htmlFor="phone" className="form-label">Telefono</label>
                    <input
                        type="number"
                        className="form-control "
                        id="phone"
                        name="phone"
                        value={formState.phone}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="mb-3 col-sm-6">
                    <label htmlFor="email" className="form-label">Email</label>
                    <input
                        type="email"
                        className="form-control "
                        id="email"
                        name="email"
                        value={formState.email}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="mb-3">
                    <label htmlFor="consulta" className="form-label">Cuentanos</label>
                    <textarea
                        className="form-control"
                        id="consulta"
                        name="consulta"
                        value={formState.consulta}
                        onChange={handleChange}
                        required
                        rows={6}
                    />
                </div>
                <button type="submit" className="btn btn-light border border">Enviar</button>
            </form>
        </>
    );
};

export default FormContacto;
