import React from 'react';
import './App.css';
import FotoSlider from './components/FotoSlider';
import NavigationBar from './components/NavigationBar';
import Footer from './components/Footer';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css'
import Galeria from './components/Galeria';
import FormContacto from './components/FormContacto';

function App() {
  return (
    <div>
      <FotoSlider />
      <NavigationBar />
      <main className="container pt-5" id='Inicio'>
        <p className='text-center'>
          <label className='fw-bolder'>VPM</label> se funda como oficina en el 2024 bajo la convicción de crear mejoras para el habitar, fusionando la ergonomia propia e individual de cada usuario junto a los mejores materiales y terminaciones.
          Buscamos que cada proyecto refleje la personalidad y estilo de vida de cada usuario, respetando y guiando de la mejor forma a cada uno.
        </p>
        <h3 className='mx-auto text-center mb-5 mt-5 fw-bolder fs-4'>

          Remodelación <span>-</span> Diseño <span>-</span> Construcción
        </h3>

        <Galeria />
        <div id='SobreNosotros'>
        <h3 className='mx-auto text-center pb-5 pt-5 fw-bolder fs-4' >
          Sobre nosotros
        </h3>
        <p className='text-center'>
          Somos una oficina de arquitectura liderada por jóvenes multidisciplinario y apasionados por lo que hacemos. Tenemos experiencia en el área de construcción y de desarrollo de proyectos de diversa índole, abarcando el área habitacional, comercial y turístico .
          Nuestra oficina busca el equilibrio entre las necesidades del usuario y el habitar.
        </p>
        </div>
        <div id='Contacto'>
          <FormContacto />
        </div>
      </main>
      <Footer />
    </div>
  )
}

export default App;
