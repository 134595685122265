import React from 'react';
import { Gallery } from 'react-grid-gallery';
import { images as IMAGES } from "../components/Images";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/dist/styles.css";
import { useState } from 'react';

const slides = IMAGES.map(({ src, width, height }) => ({
    src: src
}));
const Galeria = () => {

    const [index, setIndex] = useState(-1);
    const handleClick = (index: number) => setIndex(index);

    return (<>
        <Gallery rowHeight={360} images={IMAGES}
            onClick={handleClick}
            enableImageSelection={false}
        />
        <Lightbox
            slides={slides}
            open={index >= 0}
            index={index}
            close={() => setIndex(-1)}
        />
    </>)
}

export default Galeria;
