import React from 'react';
import { Container } from 'react-bootstrap';

const Footer: React.FC = () => {
    return (
        <footer className="bg-light text-center text-lg-start mt-5 py-2">
            <Container className="py-4">
                <div className='logo-footer mb-4'>
                    <img className='m-auto d-block col-3' src="logos/logo.png" alt="Logo" />
                </div>
                <div className='footer-data'>
                    <p><i className='bi bi-envelope'></i><a href="mailto:serv.arq.vpm@gmail.com">serv.arq.vpm@gmail.com</a></p>
                    <p>
                        <i className="bi bi-telephone"></i>
                        <a href="tel:+56927229099">+56927229099</a>
                        <a href="tel:+56994519649">+56994519649</a>
                    </p>

                    <p><i className="bi bi-instagram"></i><a href="https://www.instagram.com/serv.arq.vpm" target="_blank" rel="noopener noreferrer">serv.arq.vpm</a></p>
                    <p><i className='bi bi-whatsapp'></i><a href='https://wa.me/56927229099' target="_blank" rel="noopener noreferrer">+56927229099</a></p>
                </div>

                <div className="text-center mt-4">
                    © {new Date().getFullYear()} VPM - Todos los derechos reservados.
                </div>
            </Container>
        </footer>
    );
};

export default Footer;