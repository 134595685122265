import React from 'react';
import { Carousel } from 'react-bootstrap';

const FotoSlider = () => {
    return (

        <div style={{position:"relative"}}>
            <img src="logos/logo.png" alt="Logo" className="centered-logo" />
            <Carousel interval={3000} fade={true} controls={false} className='fullscreen-carousel'>
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src="carrousel/1.png"
                        alt="First slide"
                    />
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src="carrousel/2.png"
                        alt="Second slide"
                    />
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src="carrousel/3.png"
                        alt="Third slide"
                    />
                </Carousel.Item>
            </Carousel>
        </div>
    );
};

export default FotoSlider;
